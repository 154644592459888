import { Link } from "gatsby"
import React from "react"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import PageHeaders from "./pageHeaders"
import LIC from "../images/LIC_Title.jpg"

const MainButton = styled.button`
  color: #fff;
  background: #f07525;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  width: 420px;
  padding: 0.8rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  @media (max-width: 640px) {
    width: 100%;
  }
`

const SecondaryButton = styled.button`
  color: #fff;
  background: #312e2e;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  font-family: Montserrat;
  font-weight: 600;
  border-radius: 8px;
  text-transform: uppercase;
  margin-top: 2rem;
  padding: 0.8rem 1rem;
  font-size: 1.2rem;
  border: 0;
  cursor: pointer;
  @media (max-width: 640px) {
    width: 100%;
  }
`

const SermonSeriesPhoenix = () => (
  <div>
    <PageHeaders>Current Sermon Series</PageHeaders>
    <div
      css={css`
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 4rem;
        @media (max-width: 940px) {
          flex-direction: column;
        }
      `}
    >
      <img
        css={css`
          width: 360px;
          margin-top: 24px;
          border-radius: 6px;
          @media (max-width: 940px) {
            width: 100%;
          }
        `}
        src={LIC}
        alt="Living in Corinth sermon graphic title image"
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          @media (max-width: 940px) {
            margin-top: 2rem;
          }
        `}
      >
        <MainButton>
          <Link
            css={css`
              text-decoration: none;
              color: #fff;
            `}
            to="/phoenix/sermons/daniel-6"
          >
            Watch Latest Sermon
          </Link>
        </MainButton>
        <SecondaryButton>
          {" "}
          <Link
            css={css`
              text-decoration: none;
              color: #fff;
            `}
            to="/phoenix/sermons"
          >
            See all sermons
          </Link>
        </SecondaryButton>
      </div>
    </div>
  </div>
)

export default SermonSeriesPhoenix
